
import { IPackage, IInvoice } from "@/interfaces/mlm";
import {
  dispatchGetOrCreateInvoice,
  dispatchGetPackages,
  dispatchGetPaymentStatus,
  dispatchProcessConfirmInvoice, dispAuthFetch,
} from "@/store/mlm/actions";
import { readActivePackage, readInvoiceResponse } from "@/store/mlm/getters";
import { commitSetInvoiceResponse } from "@/store/mlm/mutations";
import { dispatchGetActivePackage } from "@/store/mlm/actions";
import { Component, Vue, Prop } from "vue-property-decorator";
import { dispatchGetUserProfile } from "@/store/main/actions";
import { readUserProfile } from "@/store/main/getters";
import ChangeEmailModal from "@/components/Main/ChangeEmailModal.vue";
import ByTariffFromDexNetTokenModal from "@/components/Main/ByTariffFromDexNetTokenModal.vue";

@Component({
  components: {ByTariffFromDexNetTokenModal, ChangeEmailModal},
})
export default class PackagePaymentModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;
  // @ts-ignore
  @Prop({ required: true, default: null }) public readonly pack: IPackage;

  public loadingState: any = {
    standart: false,
    submit: false,
  };
  public paymentMethod: string = "wallet";
  public standart: string = "";
  public invoiceDetail: IInvoice | null = null;
  public transaction: any = null;
  public progressPercent = 100;
  public timeToReload = 15;
  public isModalClosed: boolean = false;
  public isShowModalByTariffFromDexNetToken: boolean = false
  public myReferral: any = null
  public isChecked = false

  public async mounted() {
    commitSetInvoiceResponse(this.$store, null);
    this.paymentMethod = "wallet";
    await this.handleChangeMethod("BEP20");
    if (
      this.invoiceResponse &&
      this.userProfile &&
      Number(this.userProfile.balances.units_total) <
        Number(this.invoiceResponse.crypto_amount)
    ) {
      this.paymentMethod = "crypto";
    }
    this.updateProgressBar();

    this.myReferral = await this.informationMy()
  }

  public async informationMy() {
    const invoiceResp = await dispAuthFetch(this.$store, {
      input: "/api/v1/mlm/referral_confirmation/",
      init: {
        method: "GET",
      },
    });

    if (invoiceResp.status === 200) {
      const invoiceResponse = await invoiceResp.json();
      return invoiceResponse;
    } else {
      this.$toast.error("Failed to create invoice, please try again later", {
        message: "Failed to create invoice, please try again later",
        duration: 3000,
        position: "bottom",
      });
      return null;
    }
  }

  ///api/v1/mlm/referral_confirmation/

  public async handleChangeMethod(standart: string) {
    if (this.standart != standart) {
      this.loadingState.standart = true;
      this.standart = standart;
      // if (this.activePackage && this.activePackage.active && this.pack.upgrade) {
      //   await dispatchGetOrCreateUpgradeInvoice(this.$store, {package: this.pack.id, standart: this.standart, token: 'USDT' });
      // } else {
      await dispatchGetOrCreateInvoice(this.$store, {
        package: this.pack.id,
        standart: this.standart,
        token: "USDT",
      });
      // }
      if (this.invoiceResponse && this.invoiceResponse.invoice) {
        this.invoiceDetail = this.invoiceResponse.invoice;
      }
      this.progressPercent = 100;
      this.timeToReload = 15;
      this.loadingState.standart = false;
    }
  }

  public refreshTimer() {
    this.progressPercent = 0;
    this.timeToReload = 0;
  }

  public async updateProgressBar() {
    if (this.isModalClosed) {
      return;
    }

    this.progressPercent = this.progressPercent - 6.66;
    this.timeToReload = this.timeToReload - 1;
    if (this.progressPercent > 0) {
      setTimeout(() => this.updateProgressBar(), 1000);
    } else {
      await dispatchGetUserProfile(this.$store);
      this.progressPercent = 100;
      this.timeToReload = 15;
      await this.updatePaymentInfo();
      this.updateProgressBar();
    }

    // if (this.invoiceDetail && this.invoiceDetail.status === 'WAIT_PAYMENT') {
    //   this.progressPercent = this.progressPercent - 6.66;
    //   this.timeToReload = this.timeToReload - 1;
    //   if (this.progressPercent > 0) {
    //     setTimeout(() => this.updateProgressBar(), 1000);
    //   } else {
    //     await this.updatePaymentInfo();
    //   }
    // } else if (this.invoiceDetail && this.invoiceDetail.status !== 'WAIT_PAYMENT') {
    //   dispatchGetUserProfile(this.$store);
    //   await dispatchGetActivePackage(this.$store);
    //   // @ts-ignore
    //   this.$toast.success(this.$t('Order payment successful'), {
    //     duration: 3000, position: 'bottom',
    //   });
    //   this.closeModal();
    // }
  }

  public async handleProcessPayment() {
    if(!this.isChecked) return;
    if (this.invoiceDetail && this.invoiceResponse) {

      if(this.paymentMethod === 'DexNetToken'){
        this.isShowModalByTariffFromDexNetToken = true
      } else {
        const balance = this.userProfile
            ? Number(this.userProfile.balances.units_total)
            : 0;
        if (balance >= Number(this.invoiceResponse.crypto_amount)) {
          this.loadingState.submit = true;
          const result = await dispatchProcessConfirmInvoice(this.$store, {
            invoice: this.invoiceDetail.id,
          });
          if (result === true) {
            dispatchGetUserProfile(this.$store);
            await dispatchGetPackages(this.$store);
            await dispatchGetActivePackage(this.$store);
            // @ts-ignore
            this.$toast.success(this.$t("Order payment successful"), {
              duration: 3000,
              position: "bottom",
            });
            this.closeModal();
          } else {
            if (result.invoice) {
              // @ts-ignore
              this.$toast.error(result.invoice[0], {
                duration: 3000,
                position: "bottom",
              });
            } else {
              // @ts-ignore
              this.$toast.error(this.$t("Order payment error"), {
                duration: 3000,
                position: "bottom",
              });
            }
          }
          this.loadingState.submit = false;
        } else {
          // @ts-ignore
          this.$toast.error(this.$t("Insufficient funds"), {
            duration: 3000,
            position: "bottom",
          });
        }
      }
    }
  }

  public needSomeMoney () {
    const balance = this.userProfile
        ? Number(this.userProfile.balances.units_total)
        : 0;
    const r = Math.abs(balance - this.pack.price)
    return Math.round(r);
}
  public async updatePaymentInfo() {
    if (this.invoiceResponse && this.invoiceDetail) {
      const paymentResponse = await dispatchGetPaymentStatus(this.$store, {
        address: this.invoiceResponse.address,
        invoice: this.invoiceDetail.id,
      });
      if (paymentResponse) {
        this.invoiceDetail = paymentResponse.invoice;
        // this.transaction = paymentResponse.transaction;
      }
      // this.progressPercent = 100;
      // this.timeToReload = 15;
      // this.updateProgressBar();
    }
  }

  public closeModal() {
    this.isModalClosed = true;
    this.$emit("close-modal");
  }

  get invoiceResponse() {
    return readInvoiceResponse(this.$store);
  }

  get activePackage() {
    return readActivePackage(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
}
