
import { dispatchUpdateUserAvatar, dispatchUpdateUserProfile } from '@/store/main/actions';
import { readUserProfile } from '@/store/main/getters';
import { readActivePackage } from '@/store/mlm/getters';
import { Component, Vue , Prop} from 'vue-property-decorator';
import {dispAuthFetch} from "@/store/mlm/actions";


@Component({
  components: {
  },
})
export default class ProvideProfileDetailsModal extends Vue {
  // @ts-ignore
  @Prop({required: true, default: false}) readonly public modalShow: boolean;
  public loadingState: any = {
    submit: false,
  };
  public avatarModalShow: boolean = false;
  public selectedCountry: string = "Country"
  public fName: string = '';
  public lName: string = '';
  public telegram: string = '';
  public isRegistration: boolean = false;
  public errorField = {
    country: false,
    fName: false,
    // lName: false,
    // telegram: false,
  }

  public countryList = []

  public async mounted() {
    this.fName = this.userProfile!!.first_name
    this.lName = this.userProfile!!.last_name
    this.telegram = this.userProfile!!.tg_username

    this.isRegistration = localStorage.getItem("isRegistration") === "true"
    if(this.isRegistration) return;

    const invoiceResp = await dispAuthFetch(this.$store, {
      input: "/api/v1/accounts/countries_list/",
      init: {
        method: "GET",
      },
    });

    if (invoiceResp.status === 200) {
      this.countryList = await invoiceResp.json();
    } else {
      this.$toast.error("Failed to create invoice, please try again later", {
        message: "Failed to create invoice, please try again later",
        duration: 3000,
        position: "bottom",
      });
    }
  }

  public handleLoadAvatar() {
    if (this.$refs.myFileInput) {
      // @ts-ignore
      this.$refs.myFileInput.click();
    }
  }
  public async handleChangeImage(event) {
    const formData = new FormData();
    formData.append('avatar', event.target.files ? event.target.files[0] : '');
    await dispatchUpdateUserAvatar(this.$store, formData);
  }

  public isEmptyOrNull(value) {
    return value === null || value === "";
  }

  public async handleSubmitCredentials() {
    if(!this.userProfile?.country) {
      this.errorField.country = this.isEmptyOrNull(this.selectedCountry) || this.selectedCountry == 'Country'
    }
    if(!this.userProfile?.first_name) {
      this.errorField.fName = this.isEmptyOrNull(this.fName)
    }
    // if(!this.userProfile?.last_name) {
    //   this.errorField.lName = this.isEmptyOrNull(this.lName)
    // }
    // if(!this.userProfile?.tg_username) {
    //   this.errorField.telegram = this.isEmptyOrNull(this.telegram)
    // }
    if(Object.values(this.errorField).some(value => value)) return;

    const result = await dispatchUpdateUserProfile(this.$store, {
      country: this.selectedCountry,
      first_name: this.fName,
      last_name: this.lName,
      tg_username: this.telegram
    });
    if (result) {
      this.$toast.success(this.$t('Saved successfully').toString());
      this.closeModal();
    } else {
      this.$toast.error(this.$t('Failed to save').toString());
    }
  }

  public closeModal() {
    localStorage.removeItem("isRegistration")
    this.$emit('close-modal');
  }
  get activePackage() {
    return readActivePackage(this.$store);
  }
  get userProfile() {
    return readUserProfile(this.$store);
  }
}
